import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
      width: '25ch',
    },
  },
}));

export default function BasicTextFields(props) {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location = refContainer.current.value;
  };

  const refContainer = useRef(null);

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField id="standard-basic" label={props.label} inputRef={refContainer} variant="outlined" />
    </form>
  );
}
