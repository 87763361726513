import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import process from 'process';

export const useFetch = (id) => {

    const [data, setData] = useState(null);

    const apiKey = process.env.APIKEY;

    const firebaseConfig = {
        apiKey: apiKey,
        authDomain: "misfit-programming.firebaseapp.com",
        databaseid: "https://misfit-programming.firebaseio.com",
        projectId: "misfit-programming",
        storageBucket: "misfit-programming.appspot.com",
        messagingSenderId: "873394304995",
        appId: "1:873394304995:web:e3c2ddb5aa97856a1212d6"
    }
    
    const getData = () => {
    
        firebase.initializeApp(firebaseConfig);
        const db = firebase.firestore();
        const workoutsRef = db.collection("workouts").doc(`mft-${id}`);
    
        workoutsRef
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setData(doc.data());
                } else {
                    console.error("No such document!");
                }
            })
            .catch(function(error) {
                console.error("Error getting document:", error);
            });

    }

    useEffect(() => {
        getData();
    }, []);

    return data;
};