import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import BasicTextFields from "./BasicTextFields";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Home() {
  const classes = useStyles();

  return (
      <>
        <BasicTextFields label="Enter workout number" />
        <Card className={classes.root} variant="outlined">
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            Quick Guide
            </Typography>
            <Typography variant="h6" component="h2">
            2013
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 1</Button>
            </CardActions>
            <Typography variant="h6" component="h2">
            2014
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 175</Button>
            </CardActions>
            <Typography variant="h6" component="h2">
            2015
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 470</Button>
            </CardActions>
            <Typography variant="h6" component="h2">
            2016
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 730</Button>
            </CardActions>
            <Typography variant="h6" component="h2">
            2017
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 993</Button>
            </CardActions>
            <Typography variant="h6" component="h2">
            2018
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 1263</Button>
            </CardActions>
            <Typography variant="h6" component="h2">
            2019
            </Typography>
            <CardActions>
            <Button size="small">Cycle 1: Workout 1813</Button>
            </CardActions>
        </CardContent>
        </Card>
      </>
  );
}
