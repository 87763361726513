import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import { useFetch } from '../hooks/useFetch';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    alignLeft: {
        margin: 16,
    }
});

const Data = () => {

    // TODO: Fix Data called twice.

    const classes = useStyles();

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleBottomNav = (direction) => {
        if (direction === 'before') {
            window.location = --id;
        } else {
            window.location = ++id;
        }
    }

    let { id } = useParams();

    const workout = useFetch(id);
    
    if (workout) {
        // Remove embedded iframes.
        let regex = new RegExp('<iframe[^>]*>(.*?)</iframe[^>]*>', 'gm');
        for (const key in workout) {
            workout[key] = workout[key].replace(regex, '');
        }
        const { title, content, basic, hatchet, masters, plus } = workout;
        let cards;
        document.title = `${title}`;
        if ( content ) {
            cards = (
                <>
                    <h1 className={classes.alignLeft}>{ title }</h1>
                    <Accordion square expanded={expanded === 'panel1'}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Content</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: content }} />
                        </AccordionDetails>
                    </Accordion>
                    <BottomNavigation showLabels className={classes.root}>
                        <BottomNavigationAction label="Before" icon={<NavigateBefore />} onClick={() => handleBottomNav('before')}/>
                        <BottomNavigationAction label="Next" icon={<NavigateNext />} onClick={() => handleBottomNav('next')}/>
                    </BottomNavigation>
                </>
            )
        } else {
            cards = (
                <>  
                    <h1 className={classes.alignLeft}>{ title }</h1>
                    <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>Basic</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: basic }} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography>Hatchet</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: hatchet }} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        >
                        <Typography>Masters</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: masters }} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                        >
                        <Typography>Plus</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: plus }} />
                        </AccordionDetails>
                    </Accordion>
                    <BottomNavigation showLabels className={classes.root}>
                        <BottomNavigationAction label="Before" icon={<NavigateBefore />} onClick={() => handleBottomNav('before')}/>
                        <BottomNavigationAction label="Next" icon={<NavigateNext />} onClick={() => handleBottomNav('next')}/>
                    </BottomNavigation>
                </>
            )
        }
        return (
            <>
                { cards }
            </>
        )
    } else {
        return (
            <CircularProgress className={classes.alignLeft} />
        )
    }

}

export default Data;