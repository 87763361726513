import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Header from "./components/Header";
import Home from "./components/Home";
import Body from "./components/Body";

function App() {
  return (
    <Container disableGutters>
      <Header />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/:id" component={Body} />
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
