import React from 'react';
import BasicTextFields from "./BasicTextFields";
import Data from "./Data";

function Body() {
    return (
        <>
            <BasicTextFields label="Enter workout number" />
            <Data />
        </>
    );

}

export default Body;